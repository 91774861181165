import { forwardRef } from 'react';

import TextFieldStyled from './Styled';
import TextfieldProps from './types';

const TextField = forwardRef<unknown, TextfieldProps>(({ size, ...props }, ref) => (
    <TextFieldStyled
        notched={false}
        inputRef={ref}
        size={size === 'small' || size === 'medium' ? size : 'small'}
        {...props}
    />
));

TextField.displayName = 'TextField';

export default TextField;
