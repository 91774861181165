import { OutlinedInput, Theme, styled } from '@mui/material';

import TextFieldProps from './types';

type transformPropsTypes = { theme: Theme } & TextFieldProps;

const TextFieldStyled = styled(OutlinedInput, {
    shouldForwardProp: (prop: string) => !['size'].includes(prop),
})<TextFieldProps>(({ theme, size }: transformPropsTypes) => ({
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.background.light,
    border: theme.border.medium,
    borderRadius: size === 'large' ? theme.radius.allLarge : theme.radius.all,
    fontSize:
        size === 'large'
            ? theme.typography.h5.fontSize
            : size === 'medium'
              ? theme.typography.p1.fontSize
              : theme.typography.p2.fontSize,
    fontWeight: size === 'large' ? 600 : size === 'medium' ? 500 : 'inherit',
    transition: theme.transitions.create(['border-color']),
    height: size === 'large' ? '68px' : size === 'medium' ? '56px' : theme.spacing(10),
    paddingLeft: size === 'small' ? theme.spacing(2) : size === 'large' ? theme.spacing(6) : theme.spacing(2),

    '&.MuiOutlinedInput-input': {
        padding: '14px 0',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        display: 'none',
    },
    '&:hover:not(.Mui-focused) .MuiOutlinedInput-notchedOutline, &.Mui-hoverVisible .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.cta.main,
    },
    '&:hover.Mui-error .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.error.main,
    },
    '&.Mui-focused': {
        borderColor: theme.palette.cta.main,
    },
    '& .MuiInputBase-input::placeholder, & .MuiOutlinedInput-input::placeholder': {
        opacity: '0.5',
        color: theme.palette.text.primary,
        fontStyle: 'normal',
        fontWeight: size === 'large' ? 600 : size === 'medium' ? 'inherit' : 'inherit',
    },
}));

export default TextFieldStyled;
